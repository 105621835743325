<template>
  <div class="home">
    <section class="top">
      <div>
        <img src="../assets/web3.png" alt="">
        <p>
          <span>가맹문의</span><br>
          <a href="tel:16002140" >1600-2140</a>
        </p>
      </div>
    </section>
    <section class="menu">
      <div>
        <div>
          <h3>menu</h3>
          <h4>메뉴 소개</h4>
          <img class="mo" src="../assets/web2.jpg" alt="">
          <p>
            좋은 재료와 안전한 먹거리 <br>
            한 분의 손님, <br>
            한 그릇의 음식에 정성을 다하는 <br>
            푸짐한 인심으로 <br>
            고객 감동으로 실천하여 <br>
            언제나 고객 곁에서 <br>
            옛 맛과 정성을 지켜나가겠습니다.
          </p>
          <button @click="$router.push('/menu')">
            더 보기 <img src="../assets/arrow.svg" alt="">
          </button>
        </div>
        <img src="../assets/web2.png" alt="">
      </div>
    </section>
    <section class="location">
      <div>
        <div>
          <h3>location</h3>
          <h4>매장 찾기</h4>
          <p>전국 10+ 가맹점 오픈</p>
          <button @click="$router.push('/shop')">
            더 보기 <img src="../assets/arrow.svg" alt="">
          </button>
        </div>
        <div class="shops">
          <div class="list">
            <div class="scroll">
              <div class="item" v-for="shop in shops" :style="{backgroundImage:'url('+shop.img+')'}">
                <h5><span class="name">{{shop.name}}</span><span>{{shop.address}}</span></h5>
              </div>
            </div>
          </div>
          <div class="nav">
            <button @click="prev">
              <img src="../assets/nav-left.png" alt="">
            </button>
            <button @click="next">
              <img src="../assets/nav-right.png" alt="">
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import shops from "../shops";
export default {
  name: 'Home',
  data(){
    return {
      shops:shops
    }
  },
  methods:{
    next(){
      var item = document.querySelector('.item')
      var list = document.querySelector('.list')
      if(list.scrollLeft+item.clientWidth<list.clientWidth*shops.length){
        list.scrollTo({
          left:list.scrollLeft+=item.clientWidth,
          behavior:'smooth'
        })
      }else{
        this.prev()
      }
    },
    prev(){
      var item = document.querySelector('.item')
      var list = document.querySelector('.list')
      if(list.scrollLeft-item.clientWidth>0){
        list.scrollTo({
          left:list.scrollLeft-=item.clientWidth,
          behavior:'smooth'
        })
      }else{
        this.next()
      }
    }
  }
}
</script>
