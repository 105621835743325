<template>
  <header>
    <div class="top" v-if="$route.name!=='Home'">
      <img src="./assets/logo.svg" alt="">
    </div>
    <nav ref="menu" class="menu">
      <ul>
        <li>
          <router-link to="/">
            소개
          </router-link>
        </li>
        <li>
          <router-link to="menu">
            메뉴
          </router-link>
        </li>
        <li>
          <router-link to="shop">
            매장
          </router-link>
        </li>
        <li>
          <router-link to="info">
            창업안내
          </router-link>
        </li>
        <a href="#"  @click="$refs.menu.classList.remove('open')">
          <img src="./assets/icon_close.svg" alt="">
        </a>
      </ul>
    </nav>
    <nav class="mo">
      <router-link to="/">
        <img src="./assets/icon_home.svg" alt="홈">
      </router-link>
      <a href="#" @click="$refs.menu.classList.add('open')">
        <img src="./assets/icon_menu.svg" alt="대메뉴">
      </a>
    </nav>
  </header>
  <router-view :class="($route.name!=='Home')?'padding-lg':'padding-sm'"/>
  <footer>
    <div>
      <h3>consulting</h3>
      <div>
        <h4>무료컨설팅</h4>
        <a href="tel:1600-2140">1600-2140</a>
      </div>
      <div>
        <div class="input">
          <input type="text" placeholder="성함" v-model="name">
        </div>
        <div class="input">
          <input type="text" placeholder="연락처" v-model="phone">
        </div>
        <div class="input">
          <input type="text" placeholder="가맹희망지역" v-model="location">
        </div>
      </div>
      <button @click="mail">문의하기</button>
      <div class="info">
        <p>
          <img src="./assets/footer.png" alt=""><br>
          <span>주소: 전남 순천시 해룡면 대안길 3</span>
          <span>전화: 061.901.2123</span>
          <span>팩스: 061.902.2123</span>
          <span>이메일: kjfood2123@naver.com</span>
          <span>사업자등록번호: 832-81-02082</span>
        </p>
        <p>
          <span>Copyright © 케이제이푸드(주).</span> <span>All Rights Reserved.</span>
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default{
  name:"App",
  data(){
    return {
      name:'',
      phone:'',
      location:''
    }
  },
  methods:{
    mail(){
      if(this.name && this.phone && this.location){
        let xhr = new XMLHttpRequest();
        xhr.open("POST", "http://flow-api.data-heroes.kr/api/mail");

        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onload = () => {

          alert('사장님의 소중한 창업문의가 접수되었습니다. \r 곧 연락드리겠습니다.')
        };

        let data = {
          email:'mailto:kjfood2123@naver.com',
          subject:'서울깍두기 홈페이지에서 창업문의가 들어왔습니다.',
          text:'[이름] : '+this.name+'\r[연락처] : '+this.phone+'\r[창업희망지역] : '+this.location
        }

        xhr.send(JSON.stringify(data));
      }else{
        alert('이름과 연락처, 희망지역을 입력해주세요')
      }
    }
  }
}
</script>
