export default [
  {
    name:'순천연향본점',
    address:'전남 순천시 연향번영길 139',
    img:require('./assets/shop/순천연향본점.jpg')
  },
  {
    name:'광양LF점',
    address:'전남 광양시 광양읍 순광로 466, 푸드코트C-115호',
    img:require('./assets/shop/광양LF점.jpg')
  },
  {
    name:'광양중마점',
    address:'전남 광양시 발섬길 11, 1층',
    img:require('./assets/shop/광양중마점.jpg')
  },
  {
    name:'순천오천점',
    address:'전남 순천시 오천6길 20, 가동 103, 104호',
    img:require('./assets/shop/순전_오천점.jpg')
  },
  {
    name:'순천법원점',
    address:'전남 순천시 왕지4길 7, 1층',
    img:require('./assets/shop/순천법원점.jpg')
  },
  {
    name:'여수여서점',
    address:'전남 여수시 여문1로 54',
    img:require('./assets/shop/여수여서점.jpg')
  },
  {
    name:'여수여천점',
    address:'전남 여수시 선소로 47',
    img:require('./assets/shop/여수여천점.jpg')
  },
  {
    name:'광양덕례점',
    address:'전남 광양시 광양읍 대림오성로 146-1',
    img:require('./assets/shop/광양덕례점.jpg')
  },
  {
    name:'순천신대점',
    address:'전남 순천시 해룡면 향매로 59, 1층 105호',
    img:require('./assets/shop/순천신대점.jpg')
  },
  {
    name:'순천용당점',
    address:'전남 순천시 삼산로61,1층',
    img:require('./assets/shop/용당점.jpg')
  },
]
